import IconButton from '@/components/Button/IconButton';
import { TTS_CONTENTS_CHALLENGE_URL } from '@/pages/screenplay/stores/data/config';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as DeleteIcon } from '../assets/icons/DeleteIcon.svg';
import { isShowFeedbackAtom } from '../stores/atoms/ui';

const Feedback = () => {
  const setIsShowFeedback = useSetRecoilState(isShowFeedbackAtom);
  return (
    <section className="project-feedback">
      <section className="feedback-text">
        TTS Content Challenge (10/31~11/24)
        <a target="_blank" href={TTS_CONTENTS_CHALLENGE_URL} rel="noreferrer">
          Enter now {'>'}
        </a>
      </section>
      <IconButton className="btn-exit" onClick={() => setIsShowFeedback(false)}>
        <DeleteIcon />
      </IconButton>
    </section>
  );
};
export default Feedback;
