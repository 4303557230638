import { ReactComponent as ScriptIcon } from '../../assets/icons/menu/Script.svg';
import { ReactComponent as VoiceIcon } from '../../assets/icons/menu/Voice.svg';
import { ReactComponent as VoiceCloningIcon } from '../../assets/icons/menu/VoiceCloning.svg';

export type Language = 'ko' | 'en' | 'ja';
export const languageList = ['ko', 'en', 'ja'] as Language[];
export const defaultLanguage = languageList[0] as Language;
export const SPEECH_CONTROL_LIST = [
  {
    id: 'pitch_shift',
    title: 'Pitch Shift',
    min: -24,
    max: 24,
    step: 1,
    defaultValue: 0,
  },
  {
    id: 'pitch_variance',
    title: 'Pitch Variance',
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
  {
    id: 'speed',
    title: 'Speed',
    value: 1,
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
];
export const DEFAULT_PARAMETER = {
  pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
  pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
  speed: SPEECH_CONTROL_LIST[2].defaultValue,
};
export type QuickMenuType = 'Voice' | 'Script' | 'Voice Cloning';
export type QuickMapType = {
  name: QuickMenuType;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};
// locale 적용시 변경 필요
export const QUICK_MENU: QuickMapType[] = [
  { name: 'Voice', icon: VoiceIcon },
  { name: 'Script', icon: ScriptIcon },
  { name: 'Voice Cloning', icon: VoiceCloningIcon },
];
export const MAX_LINE_TEXT_COUNT = 200;
export const ERROR_MULTIPLE_TAB_ACCESS = 'ERROR_MULTIPLE_TAB_ACCESS';
export const PERMISSION_ERROR_CODE = 403;
export const DISCORD_URL = 'https://discord.com/invite/M3h5GJzQbG';
export const ZENDESK_URL =
  'https://support.supertone.ai/hc/en-us/requests/new?ticket_form_id=9564913319183';

export const TTS_CONTENTS_CHALLENGE_URL = 'https://bit.ly/play_banner_click';

export const TERMS_OF_SERVICES_URL =
  'https://supertone.notion.site/Play-Terms-of-Service-121bc844cf5681fb8808cc7359cf5380?pvs=4';
export const MAIN_WEB_URL = 'https://www.supertone.ai/play';
export const PRIVACY_POLICY_URL =
  'https://supertone.notion.site/Privacy-Policy-2024-09-04-6eacb9c77aa54d28a0600fe8f6389296?pvs=4';

export const VOICE_CLONNING_FORM_URL = 'https://forms.gle/ZRSV7rPXVtY2gkJb6';

export const MEME_VOICE = '[Meme]';
export const DEFAULT_VOICE = '[Default]';
