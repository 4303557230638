import Button from '@/components/Button/Button';
import ObtIcon from '@/pages/screenplay/assets/images/Obt.png';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { showPaymentOverray } from '@/pages/screenplay/stores/atoms/ui';
import { Primary } from '@/styles/Colors';
import React from 'react';
import { useSetRecoilState } from 'recoil';

import Modal, { ModalProps } from '../Modal';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import StyledPayModal from './StyledPayModal';

interface PayModalProps
  extends Pick<
    ModalProps,
    'isOpen' | 'isPortal' | 'hideBackdrop' | 'backdropColor' | 'onClose'
  > {
  onClose: () => void;
}

const PayModal = ({ onClose, hideBackdrop }: PayModalProps) => {
  const setShowPaymentOverrayOpen = useSetRecoilState(showPaymentOverray);
  const { track } = useLog();

  return (
    <Modal isOpen={true} hideBackdrop={hideBackdrop} onClose={onClose}>
      <StyledPayModal>
        <ModalBody>
          <div className="pay-modal-header">
            <div className="title">Supertone Play</div>
            <img src={ObtIcon} alt="trial" width="50" height="28" />
            <div className="period">
              Open Beta Period: Oct 31, 2024 – Jan 31, 2025
            </div>
          </div>
          <div className="pay-modal-body">
            <div className="left-side">
              <div>
                We offer a special discount for OBT users. We simply provide:
              </div>
              <div className="offer-desc">
                <strong>
                  Unlimited usage and full access to over 150 Premium voices
                </strong>{' '}
                during the 3-month open beta.
              </div>
            </div>
            <div className="right-side">
              <div className="price">$24</div>
              <div className="price-description">
                Unlimited Access for OBT period
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="pay-modal-footer">
          <Button
            className="pay-button"
            color={Primary[400]}
            size="lg"
            onClick={() => {
              track('BUY_CONTINUE', {});
              setShowPaymentOverrayOpen(true);
            }}
          >
            Continue to Payment
          </Button>
        </ModalFooter>
      </StyledPayModal>
    </Modal>
  );
};

export default PayModal;
